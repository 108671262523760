.footer-area {
    background: #100f0f;
}
.fix {
    overflow: hidden;
}
.footer-padding {
    padding-top: 140px;
    /* padding-bottom: 50px; */
}
.footer-area .footer-logo {
    margin-bottom: 40px;
    text-align: left;
}
.footer-area .footer-tittle p {
    color: #a8a8a8;
    text-align: left;
}
.footer-area .footer-pera p {
    color: #a8a8a8;
    font-size: 15px;
    margin-bottom: 50px;
    line-height: 1.8;
}
.footer-area .footer-pera {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

   
}
.footer-area .footer-pera  a{
  color: ;

   
}





.footer-area .footer-social a i {
    color: #222222;
    margin-right: 12px;
    font-size: 19px;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
}
.footer-area .footer-tittle h4 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 29px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
}
.footer-area .footer-tittle p {
    color: #a8a8a8;
}
.footer-area .footer-form {
    margin-top: 40px;
}
.footer-area .footer-form form {
    position: relative;
}
.footer-area .footer-form form input {
    width: 100%;
    height: 43px;
    border: 0;
    color: #fc3f00;
    border-bottom: 1px solid #403f3f;
    background: none;
}
.footer-area .footer-form form .form-icon button {
    position: absolute;
    top: 0;
    right: -20px;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 13px 22px;
    line-height: 1;
}
.footer-area .footer-tittle h4 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 29px;
    font-weight: 500;
    text-transform: capitalize;
}
.footer-area .insta-feed {
    padding-top: 5px;
    margin: -2px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}
.footer-area .insta-feed li {
    width: 24.33%;
    margin: 2px;
    
}
.footer-area .insta-feed li img {
    width: 100%;
    margin: 2px;
    height: 100%;
}
.footer-area .insta-feed li a >div :nth-child(1) {
  color: #a8a8a8  ;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: normal;
  font-weight: bold;

}

.footer-area .insta-feed li a >div :nth-child(2)  {
    color: #a8a8a8  ;
    font-size: 12px;
    line-height: normal;
  }
  
.footer-bottom-area {
    background: #100f0f;
}
.footer-bottom-area .footer-border {
    border-top: 1px solid #242323;
    padding: 40px 0px 23px;
}
.footer-bottom-area .footer-copy-right p {
    color: #838383;
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    margin-bottom: 12px;
    text-align: left;
}

.footer-menu ul li:first-child {
    margin-left: 0px;
}
.footer-menu ul li {
    display: inline-block;
    margin-left: 36px;
}
.footer-menu ul li a {
    color: #777777;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
}
.footer-bottom-area .footer-copy-right p a ,.footer-bottom-area .footer-copy-right i{
    color: #f27420;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px){
    .footer-padding {
        padding-top:30px;
        padding-bottom: 10px;
    }
    }
    .content-wrapper{
        padding-top: 3.3vmax;
    padding-bottom: 20px;
    padding-left: 4vw;
    padding-right: 4vw;
    }
    
    .fe-block-d2c0f9d2513167844884 {
        grid-area: 1/2/3/11;
        z-index: 0;
    } 
    .accordion-item__click-target{
        padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    -webkit-appearance: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    touch-action: manipulation;
    }
    .accordion-item__dropdown--open {
        display: block;
    }
    .accordion-item__dropdown {
        box-sizing: border-box;
    }
    .accordion-item__description{
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        min-width: 74.5%;
        max-width: 300px;
        box-sizing: border-box;
        text-align: left;
    }
    .accordion-items-container{
        padding-bottom: 50px;
        border-bottom: 1px solid gray;
        padding-left: 0;


    }
    .accordion-item__description a {
        color: palevioletred;
    }
    .accordion-item__description p {
        font-size: 1rem;
        line-height: 1.8em;
        margin: 1rem 0;
        color: #506172;
font-weight: 400;
    }

    .fluid-engine{
        display: flex;
        justify-content: center;
        /* align-items: center; */
    }
    .accordion-item__title-wrapper {
        margin: 0;
        padding: 0;
    }
    .accordion-item__click-target{
        padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px;box-sizing: border-box;
    width: 100%;
    background: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    -webkit-appearance: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    touch-action: manipulation;
    border-top: 1px solid gray;
    }
    .sqs-html-content h2{
        font-size: 50px;
    }
    
    .made-nyc{
        padding-top: 100px;
    }
    @media only screen and (max-width: 600px) {
        .sqs-html-content h2{
            font-size: 25px;
            margin-top: 10px;
        }
        .fluid-engine{
            flex-direction: column;
            width: 100%;
          
        }
        .fluid-engine >div{
width: 100%;
        }
      }