* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100%;
  font-family: "Roboto", sans-serif !important;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

p {
  font-family: "Roboto", sans-serif;
  color: #506172;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: #000;
  margin-top: 0px;
  font-style: normal;
  font-weight: 500;
  text-transform: normal;
}
#scrollUp {
  position: fixed;
  z-index: 2147483647;
  background: #fc3f00;
  height: 50px;
  width: 50px;
  right: 31px;
  bottom: 18px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 48px;
  border: 2px solid transparent;
  cursor: pointer;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-30 {
  margin-top: 30px;
}
.f-right {
  float: right;
}
.f-left {
  float: left;
}
a:hover {
  color: #fc3f00 !important;
}
a {
  outline: none !important;
  text-decoration: none !important;
}
.iframe-wrapper {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: max-content; */
}
