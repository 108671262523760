/* .main-header {
    position: relative;
    z-index: 3;
} */
.header-area .header-top {
    padding: 8px 0px;
}
.black-bg {
    background: #000;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.header-area .header-top .header-info-left ul li {
    color: #fff;
    display: inline-block;
    margin-right: 56px;
    font-family: "Roboto",sans-serif;
    font-size: 12px;
}
li {
    list-style: none;
}
.header-area .header-top .header-info-left ul li img {
    margin-right: 8px;
}
img {
    vertical-align: middle;
    border-style: none;
}
.header-area .header-top .header-info-right,.header-area .header-top .header-info-left{
    width: auto !important;

}
.header-area .header-top .header-info-right .header-social li {
    display: inline-block;
}
.header-area .header-top .header-info-right .header-social li a {
    color: #fff;
    font-size: 13px;
    padding-left: 17px;
}
.header-area .header-top .header-info-right .header-social a i {
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
}
.header-info-left ul,.header-info-right ul{
    margin: 0px;
    padding: 0px;
}
#navigation{
    display: flex;
    padding: 0;
}
.header-area .header-mid {
    padding: 9px 0px;
    background: #fff;
    position: relative;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.header-area .header-mid .header-banner img {
    width: 100%;
}
.header-area .header-bottom {
    position: sticky;
    z-index: 1;
    top:0;
    border-top: 1px solid #eee;
    box-shadow: 0px 0px 36px 0px rgba(0,0,0,0.1);
}
.header-area .header-bottom .container{
    position: sticky;
    z-index: 1;
    top:0;
}
.sticky-bar {
    left: 0;
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 10px 15px rgba(25,25,25,0.1);
    box-shadow: 0 10px 15px rgba(25,25,25,0.1);
    z-index: 9999;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25,25,25,0.1);
    background: #fff;
}
.sticky-logo.info-open {
    padding: 12px;
}
.sticky-logo {
    display: none !important;
}
.main-header .main-menu ul li {
    display: inline-block;
    position: relative;
    z-index: 1;
}
.main-header .main-menu {
    margin-right: 30px;
}
.main-header .main-menu ul li a {
    color: #002e5b;
    font-weight: 500;
    padding: 39px 19px;
    display: block;
    font-size: 14px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    text-transform: capitalize;
    outline: none;
    text-decoration: none;
}
a, button {
    color: #fff;
    outline: medium none;
}
a {
    color: #635c5c;
}

.header-area .header-right-btn {
    position: relative;
    cursor: pointer;
}
.header-area .header-right-btn i {
    font-size: 15px;
    padding: 6px;
}
.header-area .search-box {
    position: absolute;
    right: 10px;
    /* opacity: 0; 
    visibility: hidden;
    transition: .3s; */
}
.header-area .search-box input {
    position: relative;
    border: 1px solid #ddd;
    padding-left: 10px;
    color: #000;
    width: 189px;
    padding-right: 34px;
    box-shadow: 0px 0px 36px 0pxrgba 142,142,142,0.1;
    -webkit-transition: all .4s ease-out 0s;
    -moz-transition: all .4s ease-out 0s;
    -ms-transition: all .4s ease-out 0s;
    -o-transition: all .4s ease-out 0s;
    transition: all .4s ease-out 0s;
    right: 24px;
    top: -26px;
}


.mobile_menu {
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 99;
}
.mobile_menu {
    position: absolute;
    right: 10px;
    width: 96%;
    z-index: 99;
}
.slicknav_menu {
    background: transparent;
    margin-top: -6px !important;
}
.slicknav_menu {
    margin: 0;
    padding: 0;
}
.slicknav_menu {
    background: transparent;
    margin-top: 10px;
}
.slicknav_menu {
    font-size: 16px;
    box-sizing: border-box;
    background: #4c4c4c;
    padding: 5px;
}
.header-area .slicknav_btn {
    top: -51px;
    right: 17px;
}
.slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: -15px;
    position: relative;
    z-index: 99;
    border: none;
    border-radius: 3px;
    top: -23px;
    padding: 5px;
    right: 5px;
    margin-top: -5px;
}
.slicknav_btn {
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: -35px;
    position: relative;
    z-index: 99;
}
.slicknav_btn {
    margin: 5px 5px 6px;
    text-decoration: none;
    background-color: none;
}
.slicknav_menu * {
    box-sizing: border-box;
}
.slicknav_btn {
    position: relative;
    display: block;
    vertical-align: middle;
    float: right;
    padding: 0.438em 0.625em 0.438em 0.625em;
    line-height: 1.125em;
    cursor: pointer;
}
.slicknav_nav {
    margin-top: 0px;
}
.slicknav_nav {
    background: #fff;
    float: right;
    margin-top: 11px;
    padding: 19px;
    width: 100%;
    border-bottom: 1px solid #eee;
}
.slicknav_nav {
    color: #fff;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
    list-style: none;
    /* overflow: hidden; */
}
.slicknav_menu * {
    box-sizing: border-box;
}
.slicknav_nav {
    clear: both;
}
.slicknav_nav li {
    display: block;
}
.header-sticky ul li a {
    padding: 10px 19px;
}
.slicknav_nav a {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
}
.slicknav_nav a {
    padding: 5px 10px;
    margin: 2px 5px;
    text-decoration: none;
    color: #fff;
}
.slicknav_nav a {
    display: block;
}
.header-sticky.sticky-bar.sticky .sticky-logo {
    display: block !important;
}
.sticky-logo.info-open {
    padding: 12px;
}
.main-header .main-menu ul li {
    display: inline-block;
    position: relative;
    z-index: 1;
}
.header-sticky.sticky-bar.sticky .main-menu ul>li>a {
    padding: 33px 15px;
}

.header-display-desktop {
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
  }
  
  .header-title-nav-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 0 67%;
    align-items: center;
  }
  .header-title {
    flex-grow: 0;
    flex-shrink: 0;
    backface-visibility: hidden;
  }
  .header-title-logo {
    width: auto;
    font-size: 0;
  }
  .header-title-logo a {
    display: inline-block;
    max-width: 100%;
    max-height: 50px;
  }
  .header-title-logo img {
    width: auto;
    max-width: 100%;
    max-height: 50px;
  }
  .header-title {
    flex-grow: 0;
    flex-shrink: 0;
    backface-visibility: hidden;
  }
  .header-nav {
    text-align: right;
    margin-left: auto;
    padding-left: 2vw;
}
  .header-nav {
    flex-grow: 1;
    flex-shrink: 1;
  }
  
  .header-announcement-bar-wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 2vw;
    pointer-events: auto;
  }
  .header-nav {
    text-align: right;
    margin-left: auto;
    padding-left: 2vw;
  }
  .header-nav {
    text-align: right;
    margin-left: auto;
    padding-left: 2vw;
  }
  .header-nav-list {
    justify-content: flex-end;
  }
  .header-nav-list {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .header-nav-list>div {
    white-space: nowrap;
  }
   a {
    color: black;
  }
  .header-layout-nav-right .header-nav-item:not(:first-child):not(.header-actions-action--cart) {
    margin-left: 1.2vw;
  }
  .header-nav-list>div {
    white-space: nowrap;
  }
  .header-nav-item:not(:first-child):not(.header-actions-action--cart) {
    margin-left: 1.2vw;
  }
  .header-nav-list>div {
    white-space: nowrap;
  }
  .nav-wrapper a, .header .language-item a {
    text-decoration: none;
    backface-visibility: hidden;
    padding: 0.1em 0;
  }
  .header-nav-item a {
    display: block;
    color: black;
  }
  .header-nav-item, .user-accounts-text-link, .cart-style-text, .icon-cart-quantity:not(.legacy-cart), .language-picker {
    font-size: calc((var(--site-navigation-font-font-size-value) - 1) * 1.2vw + 1rem);
}
.header-nav-item, .user-accounts-text-link, .cart-style-text, .icon-cart-quantity:not(.legacy-cart), .language-picker {
    font-family: var(--site-navigation-font-font-family);
    font-style: var(--site-navigation-font-font-style);
    font-weight: var(--site-navigation-font-font-weight);
    line-height: var(--site-navigation-font-line-height);
    letter-spacing: var(--site-navigation-font-letter-spacing);
    text-transform: var(--site-navigation-font-text-transform);
}
.header .header-nav-wrapper a, .header .language-item a {
    text-decoration: none;
    backface-visibility: hidden;
    padding: 0.1em 0;
}

.header-nav-list a {
    float: left;
    display: block;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    border-bottom: 3px solid transparent;
  }
  
  .header-nav-list a:hover {
    border-bottom: 3px solid black;
  }
  @media only screen and (max-width: 600px) {

    .header-title-logo img{
        max-width: 72%;
        height: 45px;
    }
    .header-nav-wrapper{
        display: flex;
    }
    .header-nav{
        padding-left: 0;

    }
    .header-announcement-bar-wrapper{
        padding: 1vw;

    }
    .header-nav-item:not(:first-child):not(.header-actions-action--cart){
        margin-left: 1.9vw;
    }
    .header-nav-list a{
        font-size: 15px;
    }
}


