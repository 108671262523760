.form-contact .form-group {
    margin-bottom: 30px;
}
.form-contact .form-control {
    border: 1px solid #e5e6e9;
    border-radius: 0px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;
}
.form-contact textarea {
    border-radius: 0px;
    height: 100% !important;
}
.form-contact .form-control {
    border: 1px solid #e5e6e9;
    border-radius: 0px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;
}
.boxed-btn {
    background: #fff;
    color: #fc3f00 !important;
    display: inline-block;
    padding: 18px 44px;
    font-family: "Roboto",sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: 0;
    border: 1px solid #fc3f00;
    letter-spacing: 3px;
    text-align: center;
    color: #fc3f00;
    text-transform: uppercase;
    cursor: pointer;
}
.about-area .about-right .about-prea p {
    font-size: 15px;
    text-align: left;
}
.section-tittle h3 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
}
.about-area .about-right .about-prea p {
    font-size: 15px;
}
.social-share .section-tittle {
    display: flex;
}
.section-tittle h3 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.4;
}
.social-share ul li {
    display: inline-block;
    margin: 0 10px;
}
.pt-30 {
    padding-top: 30px;
}
.whats-news-area .single-follow .single-box, .about-area .single-follow .single-box, .contact-section .single-follow .single-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.whats-news-area .single-follow .follow-us, .about-area .single-follow .follow-us, .contact-section .single-follow .follow-us {
    border: 1px solid #f4f4f4;
    padding-top: 10px;
    padding-bottom: 15px;
    padding-left: 13px;
    padding-right: 13px;
    border-radius: 5px;
    margin-bottom: 5px;
    width: 49%;
}
.whats-news-area .single-follow .follow-us .follow-count, .about-area .single-follow .follow-us .follow-count, .contact-section .single-follow .follow-us .follow-count {
    padding-left: 15px;
}
.whats-news-area .single-follow .follow-us .follow-count span, .about-area .single-follow .follow-us .follow-count span, .contact-section .single-follow .follow-us .follow-count span {
    color: #232323;
    font-size: 14px;
    font-weight: 500;
}
.whats-news-area .single-follow .follow-us .follow-count p, .about-area .single-follow .follow-us .follow-count p, .contact-section .single-follow .follow-us .follow-count p {
    margin-bottom: 0;
    line-height: 1;
    font-size: 11px;
    text-align: left;
}

.embed-main-wrapper{
    display: flex !important;
    align-items: flex-end !important;
    height: auto !important;
    background: red !important;
}
